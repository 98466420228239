import {
  backgroundImage,
  backgroundImageContainer,
  backgroundImageText,
  blackColor,
  cardTitle,
  container,
  description,
  hexToRgb,
  section,
  sectionDark,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx"

const imgRaised = imagesStyles.imgRaised
const imgRounded = imagesStyles.imgRounded

const hotPepperEatingContestPrizesStyle = (theme) => ({
  aboutButton: {
    margin: "1rem",
    lineHeight: "18px",
  },
  cardTitle: {
    ...cardTitle,
    marginBottom: "0px !important",
  },
  consultationFormButton: {
    float: "left",
    "& span": {
      lineHeight: "normal",
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
      marginBottom: "2rem",
    },
  },
  aboutIcon: {
    maxWidth: "100%",
  },
  aboutItem: {
    "@media (max-width: 599px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "83.333333%",
      flexBasis: "83.333333%",
    },
    "@media (max-width: 800px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  aboutTitle: {
    ...title,
    textAlign: "left",
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  description,
  servicesAboutList: {
    ...description,
    textAlign: "left",
    marginBottom: "2rem",
  },
  servicesAboutListTitle: {
    ...description,
    textAlign: "left",
    marginBottom: "1rem",
  },
  servicesDescription: {
    ...description,
    textAlign: "left",
  },
  backgroundImage: {
    ...backgroundImage,
    height: "800px",
    [theme.breakpoints.down("md")]: {
      height: "700px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "900px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "950px",
    },
  },
  backgroundImageText,
  cookOffButton: {
    margin: "1rem",
  },
  cookOffDescription: {
    ...description,
    color: whiteColor,
  },
  cookOffItem: {
    ...textCenter,
    "@media (max-width: 599px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "83.333333%",
      flexBasis: "83.333333%",
    },
    "@media (max-width: 800px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  cookOffTitle: {
    ...title,
    color: whiteColor,
    marginTop: "0px",
  },
  title: {
    ...title,
    marginBottom: ".25rem",
    marginTop: "2rem",
  },
  container,
  section: {
    ...section,
    ...sectionDark,
    ...backgroundImageContainer,
    position: "relative",
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)",
    },
  },
  judgingCard: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  judgingIconWrapper: {
    ...imgRaised,
    ...imgRounded,
    maxHeight: "500px",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "300px",
      marginBottom: "0rem",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "200px",
      marginBottom: "0rem",
    },
  },
  cookOffPrizesButton: {
    margin: "1rem 0 2rem 0",
  },
  hotPepperEatingContestPrizesCard: {
    [theme.breakpoints.down("sm")]: {
      margin: "1rem",
    },
  },
})

export default hotPepperEatingContestPrizesStyle
