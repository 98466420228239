// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import hotPepperEatingContestPrizesStyle from "assets/jss/material-kit-pro-react/views/hotPepperEatingContestPageSections/hotPepperEatingContestPrizesStyle.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(hotPepperEatingContestPrizesStyle)

export default function HotPepperEatingContestPrizes() {
  const {
    hotPepperEatingContestPrizesBackground,
    hotPepperEatingContestPrizes1,
  } = useStaticQuery(graphql`
    query hotPepperEatingContestPrizes {
      hotPepperEatingContestPrizesBackground: file(
        relativePath: {
          eq: "hot-pepper-eating-contest/hot-pepper-eating-contest-6.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      hotPepperEatingContestPrizes1: file(
        relativePath: {
          eq: "hot-pepper-eating-contest/hot-pepper-eating-contest-2.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={
          hotPepperEatingContestPrizesBackground.childImageSharp.gatsbyImageData
        }
        alt="Event Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <Card className={classes.hotPepperEatingContestPrizesCard}>
              <CardBody>
                <GridContainer
                  justifyContent="center"
                  alignItems="center"
                  className={classes.judgingCard}
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className={classes.aboutItem}
                  >
                    <GatsbyImage
                      className={classes.judgingIconWrapper}
                      image={
                        hotPepperEatingContestPrizes1.childImageSharp
                          .gatsbyImageData
                      }
                      objectPosition={"top"}
                      alt="Hot Pepper Eating Trophy"
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={8}
                    className={classes.aboutItem}
                  >
                    <h2 className={classes.title}>Scoville Showdown Prizes</h2>
                    <p className={classes.servicesAboutListTitle}>Top Prize</p>
                    <p className={classes.description}>
                      <b>1st place:</b> $100
                    </p>
                    {/* <Link to="/past-winners">
                      <Button
                        color="primary"
                        className={classes.cookOffPrizesButton}
                      >
                        View Past Winners
                      </Button>
                    </Link> */}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}
